import React from 'react';
import { connect } from 'react-redux';
import Loader from '../../atoms/Loader';
import authService from '../../../services/authService';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import GuestSignUpForm from '../../forms/guestSignUpForm';
import LinkSocialForm from '../../forms/linkSocialForm';
import GuestLoginForm from '../../forms/guestLoginForm';
import ShippingFrequencyForm from '../../forms/shippingFrequencyForm';
import * as type from '../../../store/login/actionTypes';
import * as commonType from '../../../store/common/actionTypes';
import * as constants from '../../../helpers/constants';
import lodash from "lodash";
import * as utilCommon from "../../../helpers/utilCommon";
import ReCAPTCHA from "react-google-recaptcha";
import ForgotPasswordForm from "../../forms/forgotPasswordForm";
import {
	FIRST_REQUESTED_QUOTE_TYPE_LTL,
	FIRST_REQUESTED_QUOTE_TYPE_TL,
	FIRST_REQUESTED_QUOTE_TYPE_UNDEFINED
} from "../../../helpers/constants";
import {generatePass} from "../../../helpers/utilCommon";

const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;

class GuestSignUp extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			displayLogin: lodash.get(this.props, 'form', '') === 'login',
			viewForgotPassword:false,
			hideAuth: false,
			displayLink: false,
			ftlData: null,
			firstRequestedQuoteType: FIRST_REQUESTED_QUOTE_TYPE_UNDEFINED
		}
		this.recaptchaRef = React.createRef();
	}

	componentWillMount() {
		if (lodash.has(this.props, 'location.search')) {
			if (utilCommon.isFtlQuote(this.props.location.search)) {
				this.setState(
					{
						ftlData: this.props.location.search,
						firstRequestedQuoteType: FIRST_REQUESTED_QUOTE_TYPE_TL
					}
				)
			} else if (utilCommon.isLtlQuote(lodash.get(this.props, 'redirect', ''))) {
				this.setState(
					{
						firstRequestedQuoteType: FIRST_REQUESTED_QUOTE_TYPE_LTL
					}
				)
			}
		}

		let signUpRequest = sessionStorage.getItem('signUpRequest');

		if (signUpRequest) {
			this.props.changeSignUpForm(1);
			this.props.changeSignUpMethod('native');
			this.props.saveSignUpRequest(JSON.parse(signUpRequest));
		}
	}

	//allow guest user or not
    componentWillReceiveProps(nextProps,props){
		if(nextProps.allowGuest!=this.props.allowGuest && nextProps.allowGuest){
			this.props.handleOpen(false)
			this.props.allowGuest()
		}

		if (nextProps.linkEmail) {
			this.setState({ hideAuth: true, displayLink: true });
		}
	}
	handleOpen = ()=>{
		this.props.handleOpen(false)
			this.props.allowGuest()
	}

	redirectUrl = () => {
		if (this.state.ftlData) {
			return `/customer/ftl${this.state.ftlData}`;
		}

		let url = lodash.get(this.props, 'redirect', '');

		if (url === '/home/freight-quote') {
			url += sessionStorage.getItem('quoteSearchString');
		}

		return url;
	}

	switchForm = () => {
		let form = lodash.get(this.props, 'form', '');

		if (form === 'login' || form === 'register') {
			if (this.state.displayLogin) {
				utilCommon.historyPush('/quick-register');
			} else {
				utilCommon.historyPush('/quick-signin');
			}
		}
	}

	//login a user
	guestLogin = (values)=> {
		sessionStorage.setItem('loginRedirect', this.redirectUrl());
        this.props.login(values,this.props.currentLanguage,this.props.dictionaries);
	}
	//register a user
	guestSignUp = async (values) => {
		let self = this;
		let payload = {};
		let recaptchaValue = '';
		try {
			recaptchaValue = await this.recaptchaRef.current.executeAsync();
		} catch (e) {
			return;
		}

		values.password = generatePass();

		payload.isCommercial = this.props.signUpForm;
		payload.customerFirstName = values.customerFirstName;
		payload.customerLastName = values.customerLastName;
		payload.customerEmailId = values.customerEmailId;
		payload.customerPhone = values.phone;
		payload.customerCell = values.customerCell;
		payload.password = values.password;
		payload.recaptcha = recaptchaValue;
		//payload.skipReCaptchaCheckForTests = true;

		if (values.textUpdates) {
			payload.sendSms = 1;
			payload.newsAndUpdate = 1;
			payload.tracking = 1;
			payload.discount = 1;
		}

		payload.customerShippingFrequency = Number(values.estimatedShipmentsPerMonth);
		payload.customerCompany = !lodash.isEmpty(values.customerCompany) && !lodash.isEmpty(values.customerCompany.trim())
			? values.customerCompany.trim() : (values.customerFirstName.trim() + ' ' + values.customerLastName.trim());
		payload.customerCell = values.phone;
		payload.customerPhone = values.customerCell;

		payload.customerType = self.props.customerTypeOptions.find(x => x.id == constants.COMMERCIAL_CUSTOMER_TYPE)

		let timezone = self.props.timezones.find(x => x.name.includes(utilCommon.standardTimezone()))

		if (timezone) {
			timezone = {name: timezone.name, id: timezone.id}
		}

		payload.timezoneId = timezone ? timezone : self.props.timezones[0]
		if (this.state.ftlData) {
			payload.ftl = 1;
		}

		payload.firstRequestedQuoteType = this.state.firstRequestedQuoteType;

		sessionStorage.setItem('loginRedirect', this.redirectUrl());
		this.props.signUp(payload, this.props.currentLanguage, this.props.dictionaries).then(res => {
			if (!lodash.get(res, 'data.success', false)) {
				this.setState({loading: false});
				this.props.setDisplayShippingFrequencyForm(false);
				utilCommon.historyPush('/quick-register');
			} else {
				if (payload.isCommercial) {
					this.props.changeSignUpMethod('native');
					this.props.saveSignUpRequest(values);
					this.submitShippingFrequency({
						customerShippingFrequency: Number(values.estimatedShipmentsPerMonth)
					})
				}
			}

			if (this.recaptchaRef.current) {
				this.recaptchaRef.current.reset();
			}
		});
	}

	//display login
	displayLoginSection = (flag)=>{
		this.setState({displayLogin:flag});
	}

	getShippingFrequency = (shippingFrequencyId) => {
		let self = this;

		return self.props.shippingFrequencyOptions.find(x=>x.id==shippingFrequencyId);
	}

	facebookSignIn = (response) => {
		response.timezoneId = this.props.timezones[0];
		if (!response.customerShippingFrequency) {
			response.customerShippingFrequency = 1;
		}
		response.customerType = 1;
		response.customerShippingFrequency = this.getShippingFrequency(response.customerShippingFrequency);
		sessionStorage.setItem('loginRedirect', this.redirectUrl());
		this.props.facebookSignIn(response, this.props.currentLanguage, this.props.dictionaries);
	}

	submitShippingFrequency = (values) => {
		// this.props.changeSignUpForm(false);
		const request = this.props.signUpRequest;
		request.customerShippingFrequency = values.customerShippingFrequency;
		request.customerUuid = utilCommon.getSession('customerId');
		this.setState({loading: true});
		switch (this.props.signUpMethod) {
			case 'native': {
				this.props.updateShippingFrequency(
					request,
					this.props.currentLanguage,
					this.props.dictionaries
				)

				break;
			}

			case 'facebook': {
				this.facebookSignIn(request);
				break;
			}
		}
	}

	linkAccount = (values) => {
		this.props.linkSocialAccount(values, this.props.currentLanguage, this.props.dictionaries);
	}

	displayForgotPassword = (flag) => {
		this.setState({ viewForgotPassword: flag });
	}

	forgotPassword = (values) => {
		this.props.forgotPassword({ customerEmailId: values.email }, this.props.currentLanguage, this.props.dictionaries)
	}

	render() {
		if (! lodash.isEmpty(this.state.ftlData) && utilCommon.isSpamFtlQuote(this.state.ftlData)) {
			return null;
		}

		return (
			<div>
				{ !this.state.loading ?
					(!this.props.signUpForm || this.props.signUpMethod === '') ?
						<Dialog open={this.props.open} onClose={() => {}} scroll={undefined} aria-labelledby="scroll-dialog-title" maxWidth={'md'} className="DialogBox medium not-logged-in">
							<DialogContent className="DialogBoxcontent">
								<i className="zmdi zmdi-close closePopup" onClick={() => this.props.handleOpen(false)}/>
								<DialogContentText>

									<div className="mainSection gestUserLogin">
										<div className="loginOverlay">
											<div className="leftColumn">
												<div className="wraper">
													<ul>
														<li className={this.state.displayLogin ? 'active' : ''}
															onClick={() => {this.switchForm(); this.displayLoginSection(true);}}>Login {this.state.displayLogin ?
															<i className="zmdi zmdi-long-arrow-right"/> : ''}</li>
														<li className={!this.state.displayLogin ? 'active' : ''}
															onClick={() => {this.switchForm(); this.displayLoginSection(false);}}>Sign Up for
															Free {!this.state.displayLogin ?
																<i className="zmdi zmdi-long-arrow-right"/> : ''}</li>
													</ul>
													{!this.state.displayLogin && <ul className="list">
														<li><i className="zmdi zmdi-circle"/> Lowest Price Guarantee</li>
														<li><i className="zmdi zmdi-circle"/> Compare truck, rail, and air freight
															carriers
														</li>
														<li><i className="zmdi zmdi-circle"/> Compare shipping cost and transit times
														</li>
														<li><i className="zmdi zmdi-circle"/> Real-time tracking available</li>
														<li><i className="zmdi zmdi-circle"/> Access to over 100 different carriers
														</li>
													</ul>}
												</div>
											</div>
											<div className="rightColumn">
												{this.state.hideAuth && this.state.displayLink &&
												<div className='linkSection'>
													<LinkSocialForm
														onSubmit={this.linkAccount}
														guest={true}
													/>
												</div>}
												{this.state.displayLogin && !this.state.hideAuth &&
													<div>
												<GuestLoginForm handleOpen={this.handleOpen} onSubmit={this.guestLogin}
																displayLoginSection={this.displayLoginSection}
																facebookSignIn={this.facebookSignIn}
																showFbButton={!this.state.ftlData}
																ftlData={this.state.ftlData}
																authSetion={false}
																isQuickForm={lodash.get(this.props, 'form', '')}
																displayForgotPassword={this.displayForgotPassword}
												/>
												{this.state.viewForgotPassword &&
													<ForgotPasswordForm className="guestLogin" onSubmit={this.forgotPassword}
																		displayForgot={this.displayForgotPassword}/>}</div>}
												{!this.state.displayLogin && !this.state.hideAuth &&
												<>
												<ReCAPTCHA
													ref={this.recaptchaRef}
													size="invisible"
													sitekey={RECAPTCHA_KEY}
													badge="bottomleft"
												/>
												<GuestSignUpForm handleOpen={this.handleOpen} onSubmit={this.guestSignUp}
																 facebookSignIn={this.facebookSignIn}
																 showFbButton={!this.state.ftlData}
																 ftlData={this.state.ftlData}
																 authSection={false}
																 displayLoginSection={this.displayLoginSection}
																 isQuickForm={lodash.get(this.props, 'form', '')}
												/>
												</>
												}
											</div>
										</div>
										{this.props.loading && <Loader/>}
									</div>
								</DialogContentText>
							</DialogContent>
						</Dialog> :
						<Dialog open={this.props.open} onClose={() => {}} scroll={undefined} aria-labelledby="scroll-dialog-title" maxWidth={'md'} className="DialogBoxShippingFrequency medium not-logged-in">
							<ShippingFrequencyForm
								onSubmit={this.submitShippingFrequency}
							/>
						</Dialog>
					:  <Loader/>
				}
			</div>
    );
	}
}

// map state to props

const mapStateToProps = state => ({
	signUpRequest: state.common.signUpRequest,
	signUpMethod: state.common.signUpMethod,
	signUpForm: state.common.signUpForm,
	displayShippingFrequencyForm: state.common.displayShippingFrequencyForm,
	currentLanguage: state.i18nReducer.currentLanguage,
	dictionaries: state.i18nReducer.dictionaries,
    loading: state.common.loading,
    states:state.common.masterData ? state.common.masterData.stateList :[],
    customerTypeOptions:state.common.customerTypeOptions,
    shippingFrequencyOptions:state.common.masterData ? state.common.masterData.shippingFrequencyList :[],
    countries:state.common.masterData ? state.common.masterData.countryList :[],
	allowGuest:state.login.allowGuest,
	timezones:state.common.timezones||[],
	linkEmail: state.common.linkEmail,
	linkSocialKey: state.common.linkSocialKey,
	linkSocialType: state.common.linkSocialType,
	user: utilCommon.getSession('accessToken') || utilCommon.getSession('refreshToken')
});
// map dispatch to props
const mapDispatchToProps = (dispatch) => ({
	login: (inputDTO,lang,dictionaries) => {
        authService.login(dispatch,lang,dictionaries,inputDTO,true)
	},
	signUp: (inputDTO,lang,dictionaries) => {
        return authService.guestSignUp(dispatch,lang,dictionaries,inputDTO, true);
    },
	facebookSignIn: (response, lang, dictionaries) => {
		authService.facebookSignIn(dispatch, lang, dictionaries, response, true);
	},
	allowGuest: () => {
		dispatch({type: type.ALLOW_GET_QUOTE, allowGuest: false});
	},
	changeSignUpForm: (flag) => {
		dispatch({type: commonType.CHANGE_SIGN_UP_FORM, flag});
	},
	changeSignUpMethod: (method) => {
		dispatch({type: commonType.CHANGE_SIGN_UP_METHOD, method});
	},
	setDisplayShippingFrequencyForm: (flag) => {
		dispatch({type: commonType.CHANGE_DISPLAY_SHIPPING_FREQUENCY_FORM, flag});
	},
	saveSignUpRequest: (request) => {
		dispatch({type: commonType.SAVE_SIGN_UP_REQUEST, request});
	},
	updateShippingFrequency: (inputDTO, lang, dictionaries) => {
		return authService.updateShippingFrequency(dispatch, lang, dictionaries, inputDTO, true);
	},
	linkSocialAccount: (inputDTO, lang, dictionaries) => {
		authService.linkSocialAccount(dispatch, lang, dictionaries, inputDTO, true);
	},
	forgotPassword: (inputDTO, lang, dictionaries) => {
		authService.forgotPassword(dispatch, lang, dictionaries, inputDTO)
	},
});
// connect to store
export default connect(mapStateToProps, mapDispatchToProps)(GuestSignUp);

