import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import CustomInputField from '../atoms/CustomInputField';
import CustomInput from '../atoms/customInput';
import Button from '@material-ui/core/Button';
import utilCommon from '../../helpers/utilCommon';
import commonService from '../../services/commonService';
import * as type from '../../store/common/actionTypes';
import lodash from "lodash";
import CustomCheckBox from "../atoms/CustomCheckBox";
import FtlDataForm from "./ftlDataForm";
import MaskedInput from '../atoms/MaskedInput';
import {capitalizeFirstWord} from "../../helpers/string";
import CustomSelectBox from "../atoms/CustomSelectBox";

const labels = ['Customer type','First name','Last name','Email','Phone','Password','Confirm password','Estimated shipments per month']
//form validatiobns
const validate = (formProps) => {
    const errors = {};
    const requiredFields = ['customerType','customerFirstName','customerLastName','customerEmailId','phone','password','confirmPassword','estimatedShipmentsPerMonth']
    requiredFields.map((x,i) => {
        if (!formProps[x]) {
            errors[x] = labels[i] + ' is a required field';
        }
        if (formProps[x] && formProps[x].length === 0) {
            errors[x] = labels[i] + ' is a required field';
        }
    })
    if(!formProps.customerShippingFrequency){
        errors.customerShippingFrequency = 'Shipping frequency is a required field';
    }

    if(formProps.customerEmailId &&  !utilCommon.Email_Regex.test(formProps.customerEmailId)){
        errors.customerEmailId = 'Please enter a valid email address'
    }

    if (!formProps.phone || formProps.phone.length != 10) {
        errors.formatedPhone = 'Please enter valid phone number';
    }

    if (String(formProps.customerCompany).trim() && (!formProps.customerCell || formProps.customerCell.length != 10)) {
        errors.formattedCustomerCell = 'Please enter valid business phone';
    }

    if(formProps.password && (formProps.password.length<6 ||formProps.password.length >20 )) {
        errors.password = 'Password should be alphanumeric with minimum of 6 characters'
    } else if (
        formProps.password &&
        (
            formProps.password.charAt(0) === ' ' ||
            formProps.password.charAt(0) === '\t' ||
            formProps.password.charAt(0) === '\n' ||
            formProps.password.charAt(formProps.password.length - 1) === ' ' ||
            formProps.password.charAt(formProps.password.length - 1) === '\t' ||
            formProps.password.charAt(formProps.password.length - 1) === '\n'
        )
    ) {
        errors.password = 'Space bar is prohibited as first or last character';
    } else if(formProps.password && formProps.confirmPassword && formProps.password!=formProps.confirmPassword){
        errors.confirmPassword = 'The entered passwords do not match'
    }

    return errors;
}

export class GuestSignUpForm extends React.Component{

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillReceiveProps(nextProps,props){
		if(nextProps.allowGuest!=this.props.allowGuest && nextProps.allowGuest && nextProps.handleOpen){
			this.props.handleOpen(false)
		}
	}

    componentDidMount() {
        this.props.changeDisplayPasswordInsecureWarning(false);
        this.props.getTermsConditions();
    }

    // set requiredClass
    enableRequiredClass = (key,value)=>{
        if(this.props.formValues && this.props.formValues[key]==value){
            return {requiredClass:true}
        }

        return {requiredClass:false}
    }

    setPhone = (e) => {
        this.props.change(`phone`, e.target.value.replace(/[^\d]/g, ''));
        this.checkPhoneForPassword();
    }

    setCellPhone = (e) => {
        this.props.change(`customerCell`, e.target.value.replace(/[^\d]/g, ''));
    }

    setCustomerType = (e) => {
        if (e.target.value) {
            this.props.changeSignUpForm(1);
            this.props.change('customerType', 2);
        } else {
            this.props.changeSignUpForm(0);
            this.props.change('customerType', 1);
        }
    }

    checkPhoneForPassword = () => {
        if (_.get(this.props.formValues, 'password')) {
            const password = this.props.formValues.password;

            if (
                _.get(this.props.formValues, 'phone') &&
                this.props.formValues.phone.includes(password)
            ) {
                this.props.changeDisplayPasswordInsecureWarning(true);
            } else {
                this.passwordValidation(password);
            }
        } else {
            this.props.changeDisplayPasswordInsecureWarning(false);
        }
    }

    checkNameForPassword = (e) => {
        if (_.get(this.props.formValues, 'password')) {
            const password = this.props.formValues.password;

            if (e.target.value === password) {
                this.props.changeDisplayPasswordInsecureWarning(true);
            } else {
                this.passwordValidation(password);
            }
        } else {
            this.props.changeDisplayPasswordInsecureWarning(false);
        }
    }

    passwordValidation = (password) => {
        if (password.length >= 6) {
            if (
                /^[a-z]+$/.test(password) ||
                /^[A-Z]+$/.test(password) ||
                /^\d+$/.test(password) ||
                password === 'querty' ||
                (_.get(this.props.formValues, 'phone') && this.props.formValues.phone.includes(password)) ||
                (_.get(this.props.formValues, 'customerFirstName') && password === this.props.formValues.customerFirstName) ||
                (_.get(this.props.formValues, 'customerLastName') && password === this.props.formValues.customerLastName)
            ) {
                if (!this.props.displayPasswordInsecureWarning) {
                    this.props.changeDisplayPasswordInsecureWarning(true);
                }
            } else {
                if (this.props.displayPasswordInsecureWarning) {
                    this.props.changeDisplayPasswordInsecureWarning(false);
                }
            }
        } else {
            this.props.changeDisplayPasswordInsecureWarning(false);
        }
    }

    checkPassword = (e) => {
        if (e.target.value) {
            let password = e.target.value;

            this.passwordValidation(password);
        } else {
            this.props.changeDisplayPasswordInsecureWarning(false);
        }
    }

    render() {
        const { handleSubmit, submitting, valid, showFbButton=true, ftlData={}, pristine, callAPI, isLoginForm, shipmentFreq } = this.props;

        const buttonClass = this.props.authSection ? 'col-grid3' : 'col-grid1 mb0';
        const facebookAppKey = process.env.REACT_APP_FACEBOOK_KEY;
        const titleBlockClass = this.props.authSection ? 'col-grid2 formTitle' : 'col-grid2 formTitle mb0';
        const nameBlockClass = this.props.authSection ? 'col-grid2' : 'col-grid2 mb0';
        const blockClass = this.props.authSection ? 'col-grid1' : 'col-grid1 mb0';
        const colCenterClass = this.props.authSection ? 'col-center' : 'col';

        return (
            <div className="wraper">
                {!isLoginForm && <div className={titleBlockClass}>
                    <div className="col">
                        <h2 class="headerText">SIGN UP</h2>
                    </div>
                    <div className="col">
                        <div class="headerLink">Already have an account? <a onClick = {
                            (e) => {
                                this.props.displayLoginSection(true);
                                if (lodash.get(this.props, 'isQuickForm', '')) {
                                    utilCommon.historyPush( '/quick-signin');
                                }
                            }}>Login</a>
                        </div>
                    </div>
                </div>}

                <form name="GuestSignUpForm" onSubmit={handleSubmit} className="signupForm">
                    <div class="hidden">
                        <Field
                            component={CustomInput}
                            name="customerType"
                            type="hidden"
                        />
                    </div>
                    <div className={nameBlockClass}>
                        <div className="col">
                            <Field label="Company name (Optional)" maxLength={30} name="customerCompany"
                                   normalize={capitalizeFirstWord}
                                   component={CustomInputField}/>
                        </div>
                        <div className="col">
                            <Field label="Email address" type="email" maxLength={100} requiredClass
                                   name="customerEmailId" component={CustomInputField}/>
                        </div>
                    </div>
                    <div className={nameBlockClass}>
                        <div className="col">
                            <Field
                                label="First name"
                                maxLength={30}
                                requiredClass
                                name="customerFirstName"
                                component={CustomInputField}
                                onChange={this.checkNameForPassword}
                                normalize={capitalizeFirstWord}
                            />
                        </div>
                        <div className="col">
                            <Field
                                label="Last name"
                                maxLength={30}
                                requiredClass
                                name="customerLastName"
                                component={CustomInputField}
                                onChange={this.checkNameForPassword}
                                normalize={capitalizeFirstWord}
                            />
                        </div>
                    </div>
                    {this.props.displayPasswordInsecureWarning &&
                        <div className={blockClass} style={{"margin-top": "8px"}}>
                    <span className="invalidTxt">
                        You have chosen a very weak password that can be easily compromised. You can create a much
                        stronger password by adding at least 1 number, 1 upper case letter and 1 special character
                    </span>
                        </div>}
                    <div className={nameBlockClass}>
                        <div className="col">
                            <Field
                                label="Phone Number"
                                requiredClass
                                type="tel"
                                maxLength={15}
                                id="form_input_phone"
                                name="formatedPhone"
                                onChange={this.setPhone}
                                component={MaskedInput}
                                inputProps={{currentMask: 'phone'}}
                            />
                        </div>
                        <div className="col requiredClass selectSingle custom-select">
                            <Field
                                label="Estimated shipments per month"
                                requiredClass
                                id="form_input_estimatedShipmentsPerMonth"
                                name="estimatedShipmentsPerMonth"
                                options={shipmentFreq}
                                noChips={true}
                                component={CustomSelectBox}
                            />
                        </div>
                    </div>
                    <div className={blockClass} style={{marginBottom: 0}}>
                        <div className="col" style={{visibility: 'hidden', height: 0}}>
                            <Field label="Opt in to SMS communications" name="textUpdates" component={CustomCheckBox}/>
                        </div>
                    </div>
                    <div className={buttonClass}>
                        <div className="col">
                            <div className="formAction">
                                <Button type="submit" variant="contained"
                                        style={this.props.authSection ? {} : {'width': '100%'}}
                                        disabled={submitting || !valid || submitting || !valid || this.props.loading}
                                        className={(submitting || !valid || this.props.loading) ? "btnInactive" : "btnPrimary gtm-registration-submit"}>
                                    GET STARTED
                                </Button>
                            </div>
                        </div>
                    </div>
                    {!lodash.isEmpty(ftlData) && <FtlDataForm ftlData={ftlData}/>}
                </form>
                <div class="col terms" style={{textAlign: 'justify'}}>
                    <span>
                        By creating an account, you agree to our <a href="https://www.freightcenter.com/privacy-policy"
                                                                    target="_blank">Privacy Policy</a> and <a
                        href={'https://www.freightcenter.com/terms-and-conditions'} target="_blank">Terms & Conditions</a>.
                        Account creation enrolls you to receive communications about shipments status and promotions through email and SMS.
                        You may manage communications through your Account Settings.
                    </span>
                </div>
            </div>
        )
    }
}

// map dispatch to props
const mapDispatchToProps = dispatch => ({
	getMasterData: () => {
    commonService.getMasterData(dispatch)
    commonService.getTimeZone(dispatch)
	},

    changeSignUpForm: (flag) => {
	    dispatch({type: type.CHANGE_SIGN_UP_FORM, flag});
    },

    changeDisplayPasswordInsecureWarning: (flag) => {
        dispatch({type: type.DISPLAY_PASSWORD_INSECURE_WARNING, flag});
    },

    getTermsConditions: () => {
        commonService.getTermsConditions(dispatch);
    }
});


// connect to store
export default connect((state) => ({
    // initialValues: getValues(),
    signUpForm: state.common.signUpForm ? state.common.signUpForm : false,
    displayPasswordInsecureWarning: state.common.displayPasswordInsecureWarning ?
        state.common.displayPasswordInsecureWarning : false,
    states:state.common.masterData ? state.common.masterData.stateList :[],
    customerTypeOptions:state.common.customerTypeOptions,
    shippingFrequencyOptions:state.common.masterData ? state.common.masterData.shippingFrequencyList.filter(x=>x.id!=1) :[],
    countries:state.common.masterData ? state.common.masterData.countryList :[],
    formValues:state.form['GuestSignUpForm'] ? state.form['GuestSignUpForm'].values:{},
    loading: state.common.loading,
    allowGuest:state.login.allowGuest,
    termsConditions: state.common.termsConditions,
    initialValues: {
        customerType: 2,
        customerFirstName: '',
        customerLastName: '',
        customerCompany: '',
        customerEmailId: '',
        password: '',
        textUpdates: true,
        //estimatedShipmentsPerMonth: 2
    },
    shipmentFreq: [{id: 2, name: '1-time shipment'}, {id: 3, name: '1-10 / month'}, {id: 4, name: '10+ / month'}]
}), mapDispatchToProps)(reduxForm({
    form: 'GuestSignUpForm',
    validate,
    touchOnChange: true,
    touchOnBlur: true
})(GuestSignUpForm));
